import React from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import Icon from 'components/Icon'
import { ICON_BADGE_INTENT, IconBadge } from 'components/IconBadge'
import { Box, Flex } from 'components/Layout'
import Link from 'components/Link'
import { Text } from 'components/Typography'
import { ROUTE } from 'constants/routes'
import { GA4_SIGN_IN_AREAS } from 'providers/googleAnalytics'
import { COLOR_INTENT, FONT_SIZE, FONT_WEIGHT, SPACE } from 'Theme'
import { MOBILE_MENU_MARGIN } from '../constants'
import { MenuIconWrapper } from './MenuIconWrapper'

const MENU_ITEMS_HORIZONTAL_MARGIN = {
  TABLET: SPACE.PX_10,
  DESKTOP: SPACE.PX_15,
}

const ICON_BADGE_MAX = 99

interface IconsMenuProps {
  cartItemsCount: number
  isMobileMenuOpen: boolean
  closeMenu: () => void
  isMobile: boolean
  isClientSignedIn: boolean
  isExpertSignedIn: boolean
}

const LoginWrapper = styled(Flex)`
  &:hover {
    p {
      color: ${COLOR_INTENT.HEADER.ICONS.HOVER};
    }
    span {
      color: ${COLOR_INTENT.HEADER.ICONS.HOVER};
    }
    path {
      fill: ${COLOR_INTENT.HEADER.ICONS.HOVER};
    }
  }
`

export const IconsMenu: React.FC<React.PWC<IconsMenuProps>> = ({
  cartItemsCount,
  isMobileMenuOpen,
  closeMenu,
  isMobile,
  isClientSignedIn,
  isExpertSignedIn,
}) => (
  <Flex ml={MOBILE_MENU_MARGIN} alignItems="center">
    <Box mr={{ TABLET: SPACE.PX_10 }}>
      {isClientSignedIn || isExpertSignedIn ? (
        <Link
          aria-label={
            isClientSignedIn ? 'client dashboard' : 'expert dashboard'
          }
          href={isClientSignedIn ? ROUTE.CLIENT() : ROUTE.EXPERT_DASHBOARD()}
        >
          <MenuIconWrapper
            isMobile={isMobile}
            data-test-id={DATA_TEST_ID.USER_LOGGED}
            onClick={() => {
              if (isMobileMenuOpen) {
                closeMenu()
              }
            }}
          >
            <Icon.AccountIn
              color={COLOR_INTENT.HEADER.ICONS.DEFAULT}
              hoveringColor={!isMobile && COLOR_INTENT.HEADER.ICONS.HOVER}
            />
          </MenuIconWrapper>
        </Link>
      ) : (
        <Box width={{ MOBILE: 'auto', TABLET: '90px' }}>
          <Link
            href={ROUTE.SIGN_IN({
              fromUrl: GA4_SIGN_IN_AREAS.MENU,
              returnUrl: undefined,
            })}
            aria-label="sign in"
          >
            <LoginWrapper alignItems="center">
              <MenuIconWrapper
                isMobile={isMobile}
                data-test-id={DATA_TEST_ID.USER_SIGN_IN_BUTTON}
                onClick={() => {
                  if (isMobileMenuOpen) {
                    closeMenu()
                  }
                }}
              >
                <Icon.AccountOut
                  color={COLOR_INTENT.HEADER.ICONS.DEFAULT}
                  hoveringColor={!isMobile && COLOR_INTENT.HEADER.ICONS.HOVER}
                />
              </MenuIconWrapper>

              <Box display={{ MOBILE: 'none', TABLET: 'block' }}>
                <Text
                  as="p"
                  fontSize={FONT_SIZE.PX_12}
                  color={COLOR_INTENT.GRAY_90}
                >
                  Log in or
                </Text>
                <Text
                  fontSize={FONT_SIZE.PX_13}
                  fontWeight={FONT_WEIGHT.MEDIUM}
                  color={COLOR_INTENT.GRAY_90}
                >
                  Sign up
                </Text>
              </Box>
            </LoginWrapper>
          </Link>
        </Box>
      )}
    </Box>
    <Box ml={MENU_ITEMS_HORIZONTAL_MARGIN}>
      <Link href={ROUTE.SHOWROOM_CART()} aria-label="Go to cart">
        <MenuIconWrapper
          isMobile={isMobile}
          onClick={() => {
            if (isMobileMenuOpen) {
              closeMenu()
            }
          }}
        >
          <IconBadge
            content={cartItemsCount}
            intent={ICON_BADGE_INTENT.DANGER}
            max={ICON_BADGE_MAX}
          >
            <Icon.Cart
              color={COLOR_INTENT.HEADER.ICONS.DEFAULT}
              hoveringColor={!isMobile && COLOR_INTENT.HEADER.ICONS.HOVER}
            />
          </IconBadge>
        </MenuIconWrapper>
      </Link>
    </Box>
  </Flex>
)
